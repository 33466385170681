<template>
  <base-users>
    <template v-slot:default>
      <div class="my-5">
        <div class="d-flex justify-space-between">
          <v-checkbox
            v-model="checkBox"
            hide-details
            class="pa-0 text-cmm"
            :label="$t('users.showDisabledUsers')"
          ></v-checkbox>
          <v-btn :disabled="!checkPerUser" class="btn--primary btn-cus" @click="visible = 2, addNew()"
            ><v-icon class="mr-2">mdi-plus</v-icon
            >{{ $t("buttons.registerNewUser") }}</v-btn
          >
        </div>

        <v-data-table
          @click:row="getSelect($event)"
          :locale="$i18n.locale"
          :headers="headers"
          :items="staffList"
          class="elevation-2 table-custom mt-3 table-custom-select"
          id="table-cus-style"
          :footer-props="{
            itemsPerPageText:
              numLength +
              $t('common.cases') +
              itemStart +
              '~' +
              itemStop +
              $t('common.showing'),
            itemsPerPageOptions: [30, 50, 100, 200, 500]
          }"
          @pagination="getNumOfPage"
        >
          <template v-slot:[`item.role`]="{ item }"> {{ item.role.name }} </template>
          <template v-slot:[`item.facility`]="{ item }"> {{ item.facility == null ? '全施設' : item.facility.name }} </template>
          <template v-slot:[`item.disabled`]="{ item }"> {{ item.disabled == false ? '有効' : '無効' }} </template>
          <template v-slot:[`item.crmRep`]="{ item }"> {{ item.crmRep == true ? '表示あり' : '表示なし' }} </template>
          <template v-slot:[`item.profileImgUrl`]="{ item }">
            <v-avatar
              size= "34"
            >
              <img
                :src= item.profileImgUrl
              >
            </v-avatar>
          </template>
          <template v-slot:[`item.action`]="{item}">
            <v-btn class="btn-cus btn-grey" :disabled="!checkPerUser" icon @click="visible = 1, editUser(item)">
              <v-icon large>mdi-pencil-circle</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>

      <!-- Edit dialog -->
      <BaseDialog
        :key="idx"
        width="420"
        :visible="visible === 1"
        @close="visible = -1"
        :title="$t('users.editUserInformation')"
      >
        <template v-slot:content>
         <v-form ref="forms" class="forms-cus">
            <div class="t-table">
            <div class="t-table__row">
              <label>{{ $t("feesPoints.plans.enabledOrDisabled") }}</label>
              <v-select
                :items="enableOptions"
                v-model="disabledSelect"
                small
                dense
                outlined
                color="var(--text__gray)"
                hide-details
              ></v-select>
            </div>

            <div class="t-table__row">
              <label>{{ $t("common.fullName") }}</label>
              <v-text-field
                v-model="fullNameEdit"
                :rules="ruleForm.name"
                outlined
                dense
                class="pa-0"
              >
              </v-text-field>
            </div>

            <div class="t-table__row">
              <label>{{ $t("users.editEmail") }}</label>
              <v-text-field
                v-model="emailEdit"
                :rules="ruleForm.email"
                outlined
                dense
                class="pa-0"
              >
              </v-text-field>
            </div>

            <div class="t-table__row">
              <label>{{ $t("common.icon") }}</label>
              <div class="d-table-cell">
                <v-file-input class="custom-icon align-center custom-input-file"
                  :placeholder="label"
                  @change="setProfileImgUrl($event)"
                  :rules="uploadImageRules"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                ></v-file-input>
              </div>
            </div>

            <div class="t-table__row">
              <label>{{ $t("users.authorizationSettings") }}</label>
              <v-select
                :items="listRole"
                @change="getRoleId($event)"
                v-model="roleNameEdit"
                small
                dense
                outlined
                hide-details
              ></v-select>
            </div>
            <div class="t-table__row">
              <label>{{ $t("users.facilityAuthority") }}</label>
              <v-select
                :items="listNameFacility"
                @change="getFacilityId($event)"
                v-model="facilityNameEdit"
                small
                dense
                outlined
                hide-details
              ></v-select>
            </div>
            <div class="t-table__row">
              <label>{{ $t("users.allowCrm") }}</label>
              <v-select
                :items="listCRM"
                @change="getChangeCrmSelect($event)"
                v-model="selectedCrm"
                small
                dense
                outlined
                hide-details
              ></v-select>
            </div>
          </div>
         </v-form>

        </template>

        <template v-slot:footer>
          <div class="d-flex justify-end mt-4">
            <v-btn :disabled="!checkPerUser" class="btn--red-dark mr-2" @click="visible = -1">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t("buttons.close") }}
            </v-btn>
            <v-btn :disabled="!checkPerUser" class="btn--primary" @click="updateUser()">
              {{ $t("buttons.save") }}
            </v-btn>
          </div>
        </template>
      </BaseDialog>

      <!-- Add new dialog -->
      <BaseDialog
        :key="'idx' + idxCreate"
        width="450"
        :visible="visible === 2"
        @close="visible = -1"
        :title="$t('users.registerNewUser')"

      >
        <template v-slot:content>
         <v-form ref="form">
            <div class="t-table">
            <div class="t-table__row">
              <label class="custom-text">{{ $t("common.fullName") }}</label>
              <v-text-field
                v-model="fullNameNew"
                :rules="ruleForm.name"
                outlined
                dense
                class="pa-0"
              >
              </v-text-field>
            </div>

            <div class="t-table__row">
              <label class="custom-text">{{ $t("users.editEmail") }}</label>
              <v-text-field
                v-model="loginIdNew"
                :rules="ruleForm.email"
                outlined
                dense
                class="pa-0"
              >
              </v-text-field>
            </div>

            <div class="t-table__row">
              <label class="custom-text">{{ $t("common.icon") }}</label>
              <div class="d-table-cell">
                <v-file-input
                  filled
                  @change="setProfileImgUrl($event)"
                  :rules="uploadImageRules"
                  accept="image/png, image/jpeg, image/bmp"
                  :label="showLabel()"
                  prepend-icon="mdi-camera"
                ></v-file-input>
              </div>
            </div>

            <div class="t-table__row">
              <label class="custom-text">{{ $t("users.menuPermissions") }}</label>
              <v-select
                :items="listRole"
                @change="getRoleId($event)"
                :rules="ruleForm.roleId"
                v-model="roleName"
                small
                dense
                outlined
                color="var(--text__gray)"
              ></v-select>
            </div>
            <div class="t-table__row">
              <label class="custom-text">{{ $t("users.facilityAuthority") }}</label>
              <v-select
                :items="listNameFacility"
                @change="getFacilityId($event)"
                :rules="ruleForm.facilityId"
                v-model="facilityName"
                small
                dense
                outlined
                color="var(--text__gray)"
              ></v-select>
            </div>
             <div class="t-table__row">
              <label class="custom-text">{{ $t("users.allowCrm") }}</label>
              <v-select
                :items="listCRM"
                @change="getCrmSelect($event)"
                v-model="selectedCrm"
                :rules="ruleForm.crm"
                small
                dense
                outlined
                color="var(--text__gray)"
              ></v-select>
            </div>
          </div>
         </v-form>
        </template>

        <template v-slot:footer>
          <div class="d-flex justify-end mt-4 ">
            <v-btn :disabled="!checkPerUser" class="btn--red-dark mr-2 custom-btn" @click="visible = -1">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t("buttons.close") }}
            </v-btn>
            <v-btn :disabled="!checkPerUser" class="btn--primary custom-btn" @click="createUser()">
              {{ $t("buttons.save") }}
            </v-btn>
          </div>
        </template>
      </BaseDialog>
    </template>
  </base-users>
</template>

<script>
import BaseUsers from '../../../../components/Users/BaseUsers'
import BaseDialog from '@/components/Dialog/BaseDialog'
import { STAFF_LIST, GENERATE_UPLOAD_URL } from '@/api/graphql/staff/staff-info'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import axios from 'axios'
import gql from 'graphql-tag'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { isEmailValid } from '@/utils/validators.js'

export default {
  name: 'UserList',
  data () {
    return {
      label: '',
      idx: 0,
      idxCreate: 0,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      selectedCrm: '',
      checkBox: false,
      id: null,
      facilityName: '全施設',
      roleName: '',
      uploadUrl: '',
      fileUrl: '',
      file: '',
      fileImg: '',
      profileImgUrl: '',
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      listRole: [],
      staffList: [],
      headers: [
        { text: this.$t('feesPoints.plans.enabledOrDisabled'), value: 'disabled', sortable: false, width: '60px' },
        { text: this.$t('users.profileImgUrl'), value: 'profileImgUrl', sortable: false, width: '100px' },
        { text: this.$t('common.fullName'), value: 'name', sortable: false, width: '150px' },
        { text: this.$t('users.id'), value: 'id', sortable: true, width: '150px' },
        { text: this.$t('users.allowCrm'), value: 'crmRep', sortable: false, width: '90px' },
        { text: this.$t('users.email'), value: 'email', sortable: false, width: '150px' },
        // { text: this.$t('users.lastLogin'), value: 'lastLoginAt' },
        { text: this.$t('users.roleName'), value: 'role', sortable: false, width: '150px' },
        { text: this.$t('users.facilityName'), value: 'facility', sortable: false, width: '150px' },
        { text: '', value: 'action', sortable: false, width: '80px' }
      ],
      // ============= Dialogs ======================================
      visible: -1,
      uploadImageRules: [],
      ruleForm: {
        name: [v => !!v || this.$t('rules.isRequired'),
          v => v?.length < 256 || this.$t('rules.isInvalid')],
        email: [v => !!v || this.$t('rules.isRequired'),
          v => v?.length < 1024 || this.$t('rules.isInvalid'),
          v => !v || isEmailValid(v) || this.$t('rules.emailIsInvalid'),
          v => this.checkEmail(v) || this.$t('rules.uniqueEmail')],
        roleId: [v => !!v || this.$t('rules.isRequired')],
        facilityId: [v => !!v || this.$t('rules.isRequired')],
        crm: [v => !!v || this.$t('rules.isRequired')]
      },
      // Edit dialog ------------
      enableOptions: ['無効', '有効'],
      fullNameEdit: '',
      emailEdit: '',
      listAuthorSettings: ['管理者', '---'],
      authorSettingSelected: '管理者',
      listFacilityEdit: ['京都', '---'],
      facilityEditSelected: '京都',
      disabledSelect: '',
      fileImgEdit: null,
      roleNameEdit: '',
      facilityNameEdit: '全施設',
      crmRepSelectEdit: '',
      listEmail: [],
      checkCreate: false,
      getEmail: '',
      changeImg: false,

      // Add new Dialog ----------
      crmRep: true,
      listCRM: ['担当者選択に表示する', '担当者選択に表示しない'],
      fullNameNew: '',
      loginIdNew: '',
      listNameFacility: [],
      menuPermissions: [],
      menuListRole: [],
      facilityId: null,
      roleId: null,
      permissionSelected: '管理者',
      listFacilityNew: ['京都', '---'],
      facilityNewSelected: '京都'
      // ===========================================================
    }
  },
  created () {
    this.getFacility()
    this.getRoleList()
    this.getStaffList()
  },

  computed: {
    ...mapGetters(['getStatusCreateStaff'])
  },

  watch: {
    checkBox () {
      this.getStaffList()
    },

    getStatusCreateStaff () {
      this.getStaffList()
    }
  },

  methods: {
    ...mapMutations({ setStaffInfo: 'setStaffInfo' }),
    ...mapActions({
      actionCreate: 'createStaff',
      actionUpdate: 'updateStaff',
      actionGetProfileImgUrl: 'actionGetProfileImgUrl'
    }),
    getNumOfPage (pagination) {
      this.numLength = pagination.itemsLength
      this.itemStart = pagination.pageStart + 1
      this.itemStop = pagination.pageStop
    },
    async getFacility () {
      await this.$apollo.query({
        query: gql`${FACILITY_LIST}`
      }).then((data) => {
        this.menuPermissions.push({
          id: null,
          name: '全施設',
          roomTypes: [],
          shortName: '',
          type: '',
          __typename: ''
        })
        this.listNameFacility.push('全施設')
        for (let i = 0; i < data.data.facilityList.length; i++) {
          this.menuPermissions.push(data.data.facilityList[i])
          this.listNameFacility.push(data.data.facilityList[i].name)
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    async getStaffList () {
      await this.$apollo.query({
        query: gql`${STAFF_LIST}`,
        variables: {
          showDisabled: this.checkBox
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.staffList = data.data.staffList
        this.staffList.sort((a, b) => b.id - a.id)
        for (let i = 0; i < data.data.staffList.length; i++) {
          this.listEmail.push(data.data.staffList[i].email)
        }
      }).catch((error) => {
        console.error(error)
      })
    },

    checkEmail (v) {
      if (this.checkCreate === true) {
        if (this.listEmail.indexOf(v) === -1) {
          return true
        } else {
          return false
        }
      } else {
        this.listEmail = this.listEmail.filter(item => item !== this.getEmail)
        if (this.listEmail.indexOf(v) === -1) {
          this.listEmail.push(this.getEmail)
          return true
        } else {
          this.listEmail.push(this.getEmail)
          return false
        }
      }
    },

    async getRoleList () {
      await this.$apollo.query({
        query: gql`query {
          staffRoleList {
            name 
            id
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        for (let i = 0; i < data.data.staffRoleList.length; i++) {
          this.menuListRole.push(data.data.staffRoleList[i])
          this.listRole.push(data.data.staffRoleList[i].name)
        }
      }).catch((error) => {
        console.error(error)
      })
    },

    addNew () {
      this.idxCreate = this.idxCreate + 1
      this.checkCreate = true
      this.roleName = ''
      this.selectedCrm = '担当者選択に表示する'
      this.facilityName = '全施設'
      this.fullNameNew = ''
      this.fileImg = ''
      this.loginIdNew = ''
      this.roleId = ''
      this.facilityId = null
    },
    editUser (item) {
      if (item) {
        this.idx = this.idx + 1
        this.checkCreate = false
        if (item.profileImgUrl) {
          this.profileImgUrl = item.profileImgUrl
          this.fileUrl = this.profileImgUrl
        } else {
          this.profileImgUrl = null
          this.fileUrl = null
        }
        if (item.facility) {
          this.facilityName = item.facility.name
        } else {
          this.facilityName = '全施設'
        }
        if (item.role.name) {
          this.roleName = item.role.name
        }
        this.label = this.profileImgUrl
      }
    },
    async setProfileImgUrl (event) {
      if (event) {
        await this.$apollo.mutate({
          mutation: GENERATE_UPLOAD_URL,
          variables: {
            contentType: event.type,
            contentLength: event.size
          }
        }).then((data) => {
          this.uploadUrl = data.data.generateUploadUrl.uploadUrl
          this.fileUrl = data.data.generateUploadUrl.fileUrl

          this.changeImg = true
          this.fileImg = event
          axios.put(
            this.uploadUrl,
            event,
            {
              headers: {
                'Content-Type': event.type
              }
            })
        }).catch((error) => {
          console.error(error)
        })
      } else {
        this.fileUrl = null
        this.label = ''
      }
    },
    getFacilityId (event) {
      this.facilityName = event
      this.facilityId = this.menuPermissions[this.listNameFacility.indexOf(event)].id
    },
    getRoleId (event) {
      this.roleName = event
      this.roleId = this.menuListRole[this.listRole.indexOf(event)].id
    },
    getCrmSelect (event) {
      if (event === '担当者選択に表示する') {
        this.crmRep = true
      } else {
        this.crmRep = false
      }
    },

    getChangeCrmSelect (event) {
      this.selectedCrm = event
      if (event === '担当者選択に表示する') {
        this.crmRep = true
      } else {
        this.crmRep = false
      }
    },

    showLabel () {
      if (this.fileImg === '') {
        return this.$t('common.icon')
      }
    },

    createUser () {
      if (this.$refs.form.validate()) {
        const staffInfo = {
          name: this.fullNameNew,
          email: this.loginIdNew,
          profileImgUrl: this.fileUrl,
          roleId: this.roleId,
          facilityId: this.facilityId,
          facilityName: this.facilityName,
          roleName: this.roleName,
          crmRep: this.crmRep
        }
        this.setStaffInfo({ staffInfo: staffInfo })
        this.actionCreate()
        this.visible = -1
      }
    },
    getSelect (event) {
      this.id = event.id
      this.fullNameEdit = event.name
      this.emailEdit = event.email
      this.getEmail = event.email
      this.fileImgEdit = event.profileImgUrl
      this.crmRepSelectEdit = event.crmRep
      this.roleNameEdit = event.role.name
      this.facilityId = event.facility ? this.menuPermissions[this.listNameFacility.indexOf(event.facility.name)].id : null
      this.roleId = this.menuListRole[this.listRole.indexOf(event.role.name)].id
      this.facilityNameEdit = event.facility ? event.facility.name : '全施設'

      if (event.disabled === false) {
        this.disabledSelect = '有効'
      } else {
        this.disabledSelect = '無効'
      }
      if (event.crmRep === true) {
        this.selectedCrm = '担当者選択に表示する'
      } else {
        this.selectedCrm = '担当者選択に表示しない'
      }
    },
    updateUser () {
      if (this.$refs.forms.validate()) {
        if (this.disabledSelect === '無効') {
          this.disabledSelect = true
        } else {
          this.disabledSelect = false
        }
        const staffInfo = {
          id: this.id,
          disabled: this.disabledSelect,
          name: this.fullNameEdit,
          email: this.emailEdit,
          profileImgUrl: this.fileUrl,
          roleId: this.roleId,
          facilityId: this.facilityId,
          crmRep: this.crmRep,
          facilityName: this.facilityName,
          roleName: this.roleName
        }
        this.setStaffInfo({ staffInfo: staffInfo })
        this.actionUpdate().then(result => {
          if (this.id === parseInt(localStorage.getItem('id'))) {
            this.actionGetProfileImgUrl()
          }
        })
        this.visible = -1
      }
    }

  },
  components: {
    BaseUsers,
    BaseDialog
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .custom-input-file {
    .v-text-field__slot {
      white-space: nowrap;
      width: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      }
  }
}
::v-deep {
  .custom-btn .v-btn__content {
  font-size: 12px !important;
}
  .v-input__append-inner .theme--light{
    color: #424242 !important
  }
  .v-data-table {
    tbody tr td:last-child {
      text-align: end !important;
    }
  }
  .v-file-input {
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-text-field__details {
      position: absolute;
      bottom: -25px;
    }
  }
  .table-custom-select {
    .v-data-footer__pagination {
      display: none;
    }
    table tr th,
    table tr td {
      min-width: 100px !important;
    }
  }
}
.custom-icon {
  color: #757575 !important
}
.custom-text {
  font-size: 16px;
  color:#666666 !important;
  padding-bottom: 25px;
}
.t-table {
  display: table;
  border-spacing: 0 20px;
  &__row {
    display: table-row;
    padding: 20px 0;
    label,
    .v-text-field:not(.v-file-input) {
      display: table-cell;
      vertical-align: middle;
    }
    label {
      padding-right: 12px;
      text-align: right;
      white-space: nowrap;
    }
  }
}
.btn-cus {
  font-size: 16px;
}
</style>
<style lang="scss">
.v-form.forms-cus {
  margin-top: -20px;
  label,
  .v-select__selections .v-select__selection,
  .v-select__selections input,
  .v-text-field__slot input {
    color: #666;
    font-size: 16px;
  }
    .v-text-field__details {
    min-height: auto !important;
    margin-bottom: 0 !important;
    .v-messages {
      min-height: auto !important;
      .v-messages__wrapper:empty {
        display: none;
      }
    }
  }
  .custom-icon {
    padding: 0;
    margin: 0;
    .v-input__control {
      background: #f0f0f0;
      .v-input__slot {
        padding: 8px;
      }
    }
  }
  }
</style>
